import Cookie from 'js-cookie'

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: resolve => require(['@/views/'], resolve)
  }, {
    path: '/screen/',
    component: resolve => require(['@/views/screen/index'], resolve)
  }, {
    path: '/manage/',
    meta: {
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/index'], resolve),
    children: [{
        path: '/manage/',
        meta: {
          title: '管理后台',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/home'], resolve)
      },
      {
        path: '/manage/notice/lists',
        meta: {
          title: '通知公告',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/notice/lists'], resolve)
      }, {
        path: '/manage/task/lists',
        meta: {
          title: '生产工单',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/task/lists'], resolve)
      }, {
        path: '/manage/task/gantt',
        meta: {
          title: '生产排产',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/task/gantt'], resolve)
      }, {
        path: '/manage/task/report',
        meta: {
          title: '报工管理',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/task/report'], resolve)
      }, {
        path: '/manage/task/material',
        meta: {
          title: '生产领料',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/task/material'], resolve)
      },{
        path: '/manage/task/bom',
        meta: {
          title: '领料核算',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/task/bom'], resolve)
      },
      {
        path: '/manage/group/plan',
        meta: {
          title: '排班计划',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/plan'], resolve)
      }, {
        path: '/manage/group/lists',
        meta: {
          title: '班组管理',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/lists'], resolve)
      }, {
        path: '/manage/group/person',
        meta: {
          title: '班组人员',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/person'], resolve)
      },{
        path: '/manage/group/plan',
        meta: {
          title: '排班计划',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/plan'], resolve)
      }, {
        path: '/manage/group/calendar',
        meta: {
          title: '排班日历',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/calendar'], resolve)
      }, {
        path: '/manage/group/holiday',
        meta: {
          title: '节假设置',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/group/holiday'], resolve)
      }, {
        path: '/manage/quality/lists',
        meta: {
          title: '检测任务',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/quality/lists'], resolve)
      }, {
        path: '/manage/quality/items',
        meta: {
          title: '检测项目',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/quality/items'], resolve)
      }, {
        path: '/manage/quality/template',
        meta: {
          title: '检测模板',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/quality/template'], resolve)
      }, {
        path: '/manage/product/lists',
        meta: {
          title: '产品管理',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/product/lists'], resolve)
      }, {
        path: '/manage/product/item',
        meta: {
          title: '产品工序',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/product/item'], resolve)
      }, {
        path: '/manage/product/item/:id',
        meta: {
          title: '产品工序',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/product/item'], resolve)
      }, {
        path: '/manage/product/technique',
        meta: {
          title: '生产工艺',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/product/technique'], resolve)
      }, {
        path: '/manage/device/lists',
        meta: {
          title: '设备台帐',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/lists'], resolve)
      }, {
        path: '/manage/device/class',
        meta: {
          title: '设备类别',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/class'], resolve)
      },{
        path: '/manage/device/log',
        meta: {
          title: '设备运行日志',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/log'], resolve)
      },
      {
        path: '/manage/device/items',
        meta: {
          title: '检修项目',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/items'], resolve)
      }, {
        path: '/manage/device/plan',
        meta: {
          title: '检修计划',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/plan'], resolve)
      }, {
        path: '/manage/device/task',
        meta: {
          title: '检修工单',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/task'], resolve)
      }, {
        path: '/manage/device/failure',
        meta: {
          title: '设备故障',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/device/failure'], resolve)
      },{
        path: '/manage/setting/unit',
        meta: {
          title: '计量单位',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/setting/unit'], resolve)
      }, {
        path: '/manage/setting/erp',
        meta: {
          title: 'ERP集成',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/setting/erp'], resolve)
      }, {
        path: '/manage/setting/wms',
        meta: {
          title: 'WMS集成',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/setting/wms'], resolve)
      },{
        path: '/manage/setting/dardon',
        meta: {
          title: '大东集成',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/setting/dardon'], resolve)
      }, {
        path: '/manage/admin/lists',
        meta: {
          title: '管理人员',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/admin/lists'], resolve)
      }, {
        path: '/manage/admin/group',
        meta: {
          title: '管理角色',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/admin/group'], resolve)
      }, {
        path: '/manage/admin/menu',
        meta: {
          title: '管理权限',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/admin/menu'], resolve)
      },{
        path: '/manage/admin/log',
        meta: {
          title: '系统日志',
          requireAuth: true
        },
        component: resolve => require(['@/views/manage/admin/log'], resolve)
      }
    ]
  }]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth == true) { // 判断该路由是否需要登录权限
    let admin = Cookie.get('admin')
    if (admin === undefined || admin === 'e30=' || !admin) { // cookie是否存在
      next({
        path: '/' // 跳转到登录界面
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router